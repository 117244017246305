body,
html,
#root {
  background-color: white;
  height: 100%;
  --headerHeight: 2.6rem;
  --menuContainerWidth: 22rem;
}

.menu-container{
  scrollbar-color: yellow;
}
.top-left {
  top: 0;
  left: 0;
}

header {
  height: var(--headerHeight);
}

main {
  height: calc(100vh - var(--headerHeight));
}

a,
a:hover {
  text-decoration: none;
  color: black;
}

.icon {
  height: 2rem;
  width: 2rem;
  background-size: cover;
}

.bg-firebrick {
  background-color: firebrick;
}

img {
  width: 100%;
}

h1 {
  font-size: 1.7rem;
  color: white;
}

.card {
  margin-bottom: .188rem;
  background-color: white;
}

.card:hover {
  background-color: rgb(206, 205, 205);
}

.card-title {
  font-weight: 700;
}

.card-text {
  color: black;
  font-size: .8rem;
}

.card-imageOpacity {
  filter: drop-shadow(5px 5px 5px gray) grayscale(95%);
  position: relative;
  top: 0;
  left: 0;
  opacity: .5;
}

.UnitInPreview {
  background: black;
  z-index: 25;
}

.content-UnitPreview {
  background: white;
  max-height: calc(var(--vh, 1.5vh) * 100);
  margin: 0;
}

.btn {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 100%;
  margin: .5rem;
  right: 0;
  bottom: 0;
}

.btn:hover {
  color: white;
}

.main-imagePreview {
  z-index: 1;
  opacity: .5;
  filter: drop-shadow(5px 5px 5px gray) grayscale(55%);
  position: relative;
  max-height: calc(100vh - var(--headerHeight));
  max-width: calc(100vw - var(--menuContainerWidth));
}

.small-imageUnit {
  position: absolute;
  z-index: 2;
  filter: drop-shadow(5px 5px 5px gray);
  visibility: hidden;
}

i {
  font-size: 1rem;
}

.vertical-center {
  height: 100%;
  margin: auto;
}

@media only screen and (max-width: 765px) {
  .content-UnitPreview {
    margin: auto;
  }

  h1 {
    font-size: 1.4rem;
    color: white;
  }
}

@media only screen and (max-width: 352px) {
  h1 {
    font-size: 1rem;
    color: white;
  }
}

@media only screen and (max-width: 992px) {
  .menu-container {
    overflow: hidden;
  }

  .card-image {
    max-width: 100%;
    margin: auto;
  }

  .main-imagePreview {
    background-color: white;
    opacity: 1;
    filter: drop-shadow(5px 5px 5px gray) grayscale(95%);
  }
}

@media only screen and (min-width: 992px) {
  .menu-container {
    overflow: auto !important;
  }
  .menu-container {
    width: var(--menuContainerWidth);
  }
}